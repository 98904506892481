<template>
    <div id="invoice-POS" class="border p-2 rounded-md">
        <template>
            <div class="mb-4">
                <div class="logo font-bold text-xl">CHAPMAN EXPRESS</div>
                <div v-if="user.data" class="">
                    <h2 style="font-weight: 700">
                        ({{ user.data.staff.branch }})
                    </h2>
                </div>
                <!--End Info-->
            </div>
            <!--End InvoiceTop-->

            <div v-if="data" id="mid" style="padding-bottom: 20px">
                <div class="info">
                    <p>
                        Receipt No . : {{ data.receipt }} <br />
                        Meter No . : {{ data.meter_serial }} <br />
                    </p>
                    <p style="margin-top: 5px">
                        <b>{{ data.customer_name }}</b
                        ><br />
                        {{ data.meter_serial }}
                    </p>
                </div>
            </div>

            <div v-if="data" id="bot">
                <div class="token">
                    <p>Token Number</p>
                    <p>****************************************</p>
                    <p style="font-size: 20px; margin-bottom: 10px">
                        <b v-for="(token, i) in data.list_tokens" :key="i">
                            {{ formatToken(token) }}
                        </b>
                    </p>
                    <p>***************************************</p>
                </div>
            </div>

            <div v-if="data" id="mid">
                <div class="info">
                    <p>
                        Amount Paid . : GMD {{ data.total_payment }}<br />
                        Fee . : GMD {{ data.fee }} <br />
                        Cashpower Amount . : {{ data.amount }} <br />
                        Total Units . : {{ data.units }}.kWh <br />
                        Payment Date . : {{ formatDate(data.payment_date) }}
                        <br />
                    </p>
                    <p v-if="user.data" style="margin: 20px 0px 20px 0px">
                        <b>Operator: {{ user.data.name }}</b
                        ><br />
                        Use Energy Wisely!
                    </p>

                    <p v-if="data.debit_amount > 0" style="margin: 20px 0px 20px 0px; font-size: 10px">
                        You are currently receiving this unit {{data.unit}} as there is an outstanding debt of GMD {{data.debit_amount}} owed to Nawec. 
                    </p>
                </div>
            </div>

            <p v-if="user.data" style="margin-top: 10px">
                Phone: +220 438 3223 / 307 4455 -
                {{ user.data.staff.branch_contact_phone }}
            </p>
        </template>
    </div>
</template>

<script>
import moment from 'moment';
import FormatPrice from '@/mixins/FormatPrice';

export default {
    props: ['isLoading', 'data', 'user'],

    mixins: [FormatPrice],

    methods: {
        formatToken(token) {
            return token.match(/\d{4}(?=\d{2,3})|\d+/g).join('-');
        },

        formatDate(date) {
            var day = new Date(date);

            return moment().utc(day).format('Do MMM, YYYY, h:mm:ss a');
        },
    },
};
</script>

<style scoped>
#invoice-POS {
    width: 100mm;
}
#invoice-POS ::selection {
    background: #f31544;
    color: #fff;
}
#invoice-POS ::moz-selection {
    background: #f31544;
    color: #fff;
}
#invoice-POS h1 {
    font-size: 1.5em;
    color: #000;
}
#invoice-POS h2 {
    font-size: 0.9em;
}
#invoice-POS h3 {
    font-size: 1.2em;
    font-weight: 300;
    line-height: 2em;
}
#invoice-POS p {
    font-size: 1.2em;
    color: #000;
    line-height: 1.2em;
}
#invoice-POS #top,
#invoice-POS #mid,
#invoice-POS #bot {
    /* Targets all id with 'col-' */
    margin-top: 0px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}

#invoice-POS #bot .token {
    text-align: center;
    width: '100%';
}
#invoice-POS #top {
    /* min-height: 100px; */
}
#invoice-POS #mid {
    /* min-height: 80px; */
}
#invoice-POS #bot {
    /* min-height: 50px; */
}

#invoice-POS .info {
    display: block;
    margin-left: 0;
}
#invoice-POS .title {
    float: right;
}
#invoice-POS .title p {
    text-align: right;
}
#invoice-POS table {
    width: 100%;
    border-collapse: collapse;
}
#invoice-POS .tabletitle {
    font-size: 0.5em;
    background: #eee;
}
#invoice-POS .service {
    border-bottom: 1px solid #eee;
}
#invoice-POS .item {
    width: 24mm;
}
#invoice-POS .itemtext {
    font-size: 0.5em;
}
#invoice-POS #legalcopy {
}
</style>
